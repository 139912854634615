import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import './portfolio.scss';

const baseURL = window.location.href.includes("localhost") ? "http://localhost:5001" : "https://us-central1-arcade420.cloudfunctions.net"

function CollectionRowLoading() {
    return (
        <div className='d-flex mb-2'>
            <div className='loading' style={{ height: "38px", width: "38px", borderRadius: "8px" }}></div>
            <div className='flex-grow-1 loading' style={{ height: "15px", marginTop: "10px", marginLeft: "12px", borderRadius: "2px" }}></div>
        </div>
    )
}

function NFTItemLoading() {

    return (
        <div className='flex-column nft-item'>
            <div className='nft-image mb-1 loading'></div>
            <div className='w-100 loading mb-1' style={{ height: "16px", borderRadius: "2px" }}></div>
            <div className='w-100 loading' style={{ height: "16px", borderRadius: "2px" }}></div>

        </div>
    )
}

function PortfolioCard() {
    return (
        <div className='card'>
            <div className='estimated mb-1'>ESTIMATED VALUE</div>
            <div className='d-flex'>
                <div className='flex-column'>
                    <div className='d-flex'>
                        <div className='eth-image mt-1 me-1'></div>
                        <div className='eth-value'>35.24</div>
                    </div>
                    <div className='dollar-value'>$144.6K</div>
                </div>
                {/* <div>
                    fewfjewoi
                </div>
                <div>
                    fewfjewoi
                </div> */}
            </div>
        </div>
    )
}

function NFTItem(props) {
    let lastPrice = {}
    let listedPrice = {}

    if (props.details.last_sale) {
        // console.log(e.name, "last" , e.last_sale)
        lastPrice.eth = (Number(props.details.last_sale.total_price) * 0.000000001 * 0.000000001).toFixed(2);
        lastPrice.usd = (Number((lastPrice.eth * props.details.last_sale.payment_token.usd_price).toFixed(2)));
    }

    if (props.details.sell_orders && props.details.sell_orders.length > 0) {
        let order = props.details.sell_orders[0];
        listedPrice.eth = (Number(order.current_price) * 0.000000001 * 0.000000001).toFixed(2);
        listedPrice.usd = (Number((order.current_price.eth * order.payment_token_contract.usd_price).toFixed(2)));
    }

    function tapped() {
		window.webkit.messageHandlers.tappedItem.postMessage(props.details);
    }

    return (
        <div key={props.details.id} className='flex-column nft-item' onClick={tapped}>
            <div className='nft-image mb-2' style={{ background: `url(${props.details.image_thumbnail_url})` }}></div>
            <span className='title'>{props.details.name}</span>
            {(listedPrice.eth || lastPrice.eth) &&
                <div className='d-flex'>
                    <div className='eth-image mt-1 me-1'></div>
                    <span>{listedPrice.eth ? listedPrice.eth : lastPrice.eth}</span>
                </div>
            }

        </div>
    )
}

function CollectionRow(props) {
    const [items, setItems] = useState(undefined)
    const [expanded, setExpanded] = useState(false)
    const contract = props.details.primary_asset_contracts[0].address

    async function fetchForContract(contract) {
        const response = await axios.get(`${baseURL}/arcade420/us-central1/app/get_tokens?address=${props.walletAddress}&contract=${contract}`)
        return response.data
    }

    async function clickedRow(e) {
        const collectionContainer = e.target.parentNode.parentNode;
        const contract = collectionContainer.getAttribute("contract")

        setExpanded(!expanded)
        if (items) {
            return;
        }

        const newItems = []

        // fetch 
        const results = await fetchForContract(contract)
        for (let e of results) {
            newItems.push(
                <NFTItem details={e} />

            )
        }

        setItems(newItems)
    }

    return (
        <div className={`flex column collection-container ${expanded ? 'expanded' : 'collapsed'}`} contract={contract} key={contract}>
            <div className='d-flex collection-row me-3' onClick={(e) => { clickedRow(e) }}>
                <div className='nft-small-icon' style={{ background: `url('${props.details.image_url}')` }} />
                <span className='mt-1 ms-2 flex-grow-1 name'>{props.details.name}</span>
                <span className='count mt-1 ms-2'>{props.details.owned_asset_count}</span>
                <div className='caret mt-1 ms-2'>
                    <span>></span>
                </div>
            </div>
            <div className={`d-flex scrollbox mt-2 ${expanded ? 'mb-4' : ''}`}>
                {!items && expanded &&
                    <>
                        <NFTItemLoading />
                        <NFTItemLoading />
                        <NFTItemLoading />
                        <NFTItemLoading />

                    </>
                }
                {items && items}
            </div>
        </div>
    )
}

export default function Portfolio() {
    const [newNFTs, setNewNFTS] = useState(undefined)
    const [collections, setCollections] = useState(undefined)

    const { walletAddress } = useParams();

    async function fetchCollections() {
        const response = await axios.get(`${baseURL}/arcade420/us-central1/app/get_collections?address=${walletAddress}`)
        let results = []
        let items = []

        for (let e of response.data) {
            const contracts = e.primary_asset_contracts;
            if (contracts.length == 0) {
                // Some collections don't have contracts? figure out later
                continue
            }


            results.push(
                <CollectionRow details={e} walletAddress={walletAddress} />
            )
        }


        setCollections(results)
    }

    async function getAssetsForContract(contract) {

    }

    async function fetchNew() {
        try {
            const response = await axios.get(`${baseURL}/arcade420/us-central1/app/get_tokens?address=${walletAddress}`)
            let results = []

            for (let e of response.data) {
                results.push(
                    <NFTItem details={e} />
                )
            }


            setNewNFTS(results)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchCollections()
        fetchNew()
    }, [])

    return (
        <div className='portfolio flex-column'>
            <div className='ps-3'>
                <div className=' pt-3 pe-3'>
                    <div className='d-flex'>
                        <div className='search-bar mb-4 me-3 flex-grow-1 d-flex'>
                            <div className='search-icon' />
                            <input className='w-100' placeholder='Search' />
                        </div>
                        <div className='discord'>
                            <div className='discord-icon' />
                        </div>
                    </div>
                    <PortfolioCard />
                </div>
                {!newNFTs &&
                    <div className='loading' style={{ height: "16px", width: "68px", borderRadius: "2px", margin: "16px 0px 16px 0px" }}></div>
                }
                {newNFTs &&
                    <h4>New</h4>
                }
                <div className='d-flex scrollbox'>
                    {!newNFTs &&
                        <>
                            <NFTItemLoading />
                            <NFTItemLoading />
                            <NFTItemLoading />
                            <NFTItemLoading />
                            <NFTItemLoading />

                        </>
                    }
                    {newNFTs && newNFTs}
                </div>

                {!collections &&
                    <div className='loading' style={{ height: "16px", width: "28px", borderRadius: "2px", margin: "16px 0px 16px 0px" }}></div>
                }
                {collections &&
                    <h4>All</h4>
                }
                <div>
                    {!collections &&
                        <>
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                            <CollectionRowLoading />
                        </>
                    }
                    {collections}
                    {/* <div className='flex column collection-container collapsed'>
                        <div className='d-flex collection-row me-3' onClick={(e) => {
                            const className = e.target.parentNode.parentNode.className;
                            if (className.includes("collapsed")) {
                                e.target.parentNode.parentNode.className = className.replace("collapsed", "expanded")
                            } else if (className.includes("expanded")) {
                                e.target.parentNode.parentNode.className = className.replace("expanded", "collapsed")
                            }
                        }}>
                            <div className='nft-small-icon' />
                            <span className='mt-1 ms-2 flex-grow-1'>Adam Bomb Squad</span>
                            <span className='caret mt-1 ms-2'>1 ></span>
                        </div>
                        <div className='d-flex scrollbox mt-2'>
                            <div className='flex-column nft-item'>
                                <div className='nft-image mb-1'></div>
                                <b>Moji #1849</b>
                                <div className='d-flex'>
                                    <div className='eth-image mt-1 me-2'></div>
                                    <span>0.01</span>
                                </div>
                            </div>
                            <div className='flex-column nft-item'>
                                <div className='nft-image mb-1'></div>
                                <b>Moji #1849</b>
                                <div className='d-flex'>
                                    <div className='eth-image mt-1 me-2'></div>
                                    <span>0.01</span>
                                </div>
                            </div>
                            <div className='flex-column nft-item'>
                                <div className='nft-image mb-1'></div>
                                <b>Moji #1849</b>
                                <div className='d-flex'>
                                    <div className='eth-image mt-1 me-2'></div>
                                    <span>0.01</span>
                                </div>
                            </div>
                            <div className='flex-column nft-item'>
                                <div className='nft-image mb-1'></div>
                                <b>Moji #1849</b>
                                <div className='d-flex'>
                                    <div className='eth-image mt-1 me-2'></div>
                                    <span>0.01</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='flex column collection-container collapsed'>
                        <div className='d-flex collection-row me-3' onClick={(e) => {
                            const className = e.target.parentNode.parentNode.className;
                            if (className.includes("collapsed")) {
                                e.target.parentNode.parentNode.className = className.replace("collapsed", "expanded")
                            } else if (className.includes("expanded")) {
                                e.target.parentNode.parentNode.className = className.replace("expanded", "collapsed")
                            }
                        }}>
                            <div className='nft-small-icon' />
                            <span className='mt-1 ms-2 flex-grow-1'>Adam Bomb Squad</span>
                            <span className='caret mt-1 ms-2'>1 ></span>
                        </div>

                    </div> */}


                </div>
            </div>

        </div>
    )
}
