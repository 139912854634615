import logo from './logo.svg';
import './App.scss';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Login from "./Login"
import Portfolio from "./Portfolio"

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/portfolio/:walletAddress">
            <Portfolio />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}


function Home() {
  return <h2>Home</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
